// Colors
$total-white: #ffffff;
$total-black: #000000;
$white-ish: #fafafa;
$accent: #8e702f;
$accent-hover: #745e11;
$sub: #e5e5e5;

// Font Sizes Desktop
$h1: 46px;
$h2: 24px;
$h3: 18px;
$p: 16px;

// Font Sizes Mobile
$h1-mob: 32px;
$h2-mob: 22px;
