@import '@styles/variables.scss';

.wrapper {
  position: relative;
  background-color: $total-white;
}

.divider {
  background-color: $total-black;
  text-align: center;

  .pre {
    color: $accent;
  }

  .title {
    color: $total-white;
    font-size: $h1;
  }
}

.projects {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .divider {
    .title {
      font-size: $h1-mob;
    }
  }
}
