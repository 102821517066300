@import '@styles/variables.scss';

.wrapper {
  position: relative;
  height: 80vh;
  overflow: hidden;
}

.content {
  color: $total-white;
  position: relative;
  align-self: center;
}

.description {
  margin: 30px 0;
}

@media (min-width: 768px) {
  .content {
    width: 40vw;
  }
}
