@import '@styles/variables.scss';

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 0;
}

.container:last-child {
  margin-bottom: 0;
}

.title {
  flex-basis: 50%;
  margin: 0;
  font-weight: 800;
  font-size: $p;
}

.percentage {
  flex-basis: 50%;
  margin: 0 0 5px 0;
  font-weight: bold;
  text-align: right;
}

.progressBar {
  flex-basis: 100%;
  height: 5px;
  background-color: $sub;
}

.filler {
  height: 100%;
  background-color: $accent;
}
