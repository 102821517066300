@import '@styles/variables.scss';

.wrapper {
  position: relative;
  background-color: $total-white;
}

.skills {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.text {
  flex-basis: 100%;
}

.title {
  font-size: $h1;
}

.bars {
  margin-top: 20px;
  flex-basis: 100%;
}

.pre {
  color: $accent;
  margin-bottom: 6px;
}

@media (min-width: 768px) {
  .about {
    flex-wrap: nowrap;
  }

  .text {
    flex-basis: 50%;
    align-self: center;
  }

  .bars {
    flex-basis: 40%;
  }
}

@media (max-width: 768px) {
  .title {
    font-size: $h1-mob;
  }
}
