@import '@styles/variables.scss';

.item {
  position: relative;
  margin: auto;
  overflow: hidden;
  display: block;
}

.item {
  .marginFix {
    display: flex;
  }

  a:focus {
    border: 1px solid $accent;
  }
}

.overlay {
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.item:hover .overlay {
  opacity: 1;
}

.image {
  width: 100%;
}

.details {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease-in-out 0s;
}

.item:hover .details {
  top: 50%;
  left: 50%;
  opacity: 1;
}

.details h3 {
  color: $total-white;
  text-transform: uppercase;
  letter-spacing: 0.15em;
}

.details p {
  color: $total-white;
  font-size: 0.8em;
}

.fadeIn {
  top: 80%;
}
