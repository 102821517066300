@import '@styles/variables.scss';

.wrapper {
  position: relative;
  background-color: $white-ish;
}

.contacts {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  img {
    max-height: 50px;
  }
}

.card {
  box-shadow: 0 5px 20px 0 rgb(0 0 0 / 30%);
  flex-basis: 75%;
  text-align: center;
  padding: 30px;
  margin-bottom: 30px;

  .text {
    font-size: $h2;
  }

  .address {
    color: $accent;
    word-break: break-word;
  }
}

.card:last-child {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .contacts {
    justify-content: space-between;
  }

  .card {
    flex-basis: 30%;
    margin-bottom: 0;
  }
}
