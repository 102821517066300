@import '@styles/variables.scss';

.wrapper {
  position: relative;
  background-color: $white-ish;
}

.services {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 0;
}

.card {
  flex-basis: 100%;
  margin-bottom: 20px;
}

.card:last-child {
  margin-bottom: 0;
}

.content {
  margin: 0 10px;
  position: relative;
  box-shadow: 0 5px 20px 0 rgb(0 0 0 / 30%);
  background: $total-white;
}

.title,
.text {
  text-align: center;
}

.title {
  font-size: $h3;
  padding: 20px;
}

.text {
  margin-top: 0;
  padding: 0 10px 10px 10px;
  min-height: 105px;
}

.image {
  position: relative;
  flex-basis: 100%;
  height: 200px;
}

@media (min-width: 768px) {
  .services {
    flex-wrap: nowrap;
  }

  .card {
    flex-basis: 30%;
    margin-bottom: 0;
  }
}
