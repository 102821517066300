@import '@styles/variables.scss';

.wrapper {
  position: relative;
  background-color: $total-white;
}

.about {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.bio {
  flex-basis: 100%;
}

.title {
  font-size: $h1;
}

.image {
  position: relative;
  margin-top: 40px;
  flex-basis: 100%;
  text-align: center;
  align-self: center;
}

.pre {
  color: $accent;
  margin-bottom: 6px;
}

@media (min-width: 768px) {
  .about {
    flex-wrap: nowrap;
  }

  .bio {
    flex-basis: 50%;
    align-self: center;
  }

  .image {
    margin-top: 0;
    flex-basis: 40%;
    height: 700px;
  }
}

@media (max-width: 768px) {
  .title {
    font-size: $h1-mob;
  }

  .image {
    height: 500px;
  }
}
